import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CheckedSVG,
  CheckEmptySVG,
  CloseSVG,
  FilterSVG,
  LogoDecorSVG,
  SearchSVG,
} from "../assets/svgs";
import clsx from "clsx";
import { DrawerContent } from "./DrawerContent";
import { useSearchStore } from "../store/search";
import { Popover } from "@headlessui/react";

export const LeftPanel: React.FC = () => {
  const suggestions = useMemo(
    () => [
      { id: "top", text: "What is this?" },
      { id: "how", text: "How does it work?" },
    ],
    []
  );

  const [drawerContentId, openDrawerContentId] = useState("");
  const onClose = useCallback(() => openDrawerContentId(""), []);

  return (
    <>
      <div
        className={
          "relative flex h-[400px] w-full flex-shrink-0 flex-col items-center justify-center border border-essayz-border-gray bg-essayz-bg-gray p-8 py-10 lg:h-full lg:w-[540px] lg:p-12"
        }
      >
        <div className={"relative"}>
          <h1
            className={"text-[32px] font-bold leading-[39px] text-essayz-black"}
            style={{ fontFamily: "Inter" }}
          >
            Essayz
          </h1>
          <div className={"absolute top-1 -right-3.5"}>
            <LogoDecorSVG />
          </div>
        </div>
        <p className={"mt-4 text-center text-xl text-essayz-black"}>
          Use AI to explorer the wisdom of great minds
        </p>

        <SearchInput />

        <div className={"mt-6 flex flex-row items-center gap-x-6"}>
          {suggestions.map((suggestion) => (
            <button
              key={suggestion.id}
              className={
                "rounded-full border border-essayz-purple bg-essayz-purple/10 px-4 py-2 text-essayz-purple transition-colors duration-500 hover:bg-essayz-purple/20"
              }
              onClick={() => openDrawerContentId(suggestion.id)}
            >
              {suggestion.text}
            </button>
          ))}
        </div>

        <div className={"absolute bottom-5 text-lg"}>
          Created by{" "}
          <a
            href={"https://gumtech.co.uk"}
            className={"font-bold text-essayz-blue"}
          >
            Gum Technologies
          </a>
        </div>
      </div>
      <DrawerContent
        opened={!!drawerContentId}
        contentId={drawerContentId}
        onClose={onClose}
      />
    </>
  );
};

const SearchInput: React.FC = () => {
  const query = useSearchStore((state) => state.query);
  const [text, setText] = useState(query);
  useEffect(() => setText(query), [query]);
  const hasText = useMemo(() => text.trim().length > 0, [text]);
  const selectedAuthors = useSearchStore((state) => state.selectedAuthors);

  const fetchResult = useSearchStore((state) => state.fetchResult);
  const onSearch = useCallback(async () => {
    const trimmedText = text.trim();
    if (trimmedText.length > 0) {
      await fetchResult(trimmedText, selectedAuthors);
    }
  }, [text, fetchResult, selectedAuthors]);

  return (
    <div
      className={clsx(
        "mt-10 flex w-full  flex-row items-center border border-essayz-border-gray bg-white px-4",
        "group focus-within:bg-essayz-purple",
        "transition duration-500"
      )}
      style={{
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
        borderRadius: 10,
      }}
    >
      <div className={"mr-5"}>
        <SearchSVG
          className={clsx("group-focus-within:stroke-white", {
            "stroke-essayz-black": hasText,
          })}
        />
      </div>
      <input
        value={text}
        placeholder={"Ask Essayz any question"}
        className={clsx(
          "mr-4 w-full bg-transparent text-base text-essayz-black placeholder-essayz-text-gray outline-none",
          "py-4 group-focus-within:text-white group-focus-within:placeholder-white/80"
        )}
        onChange={(e) => setText(e.currentTarget.value)}
        autoComplete={"off"}
        type={"search"}
        onKeyUp={async (e) => {
          if (e.key === "Enter") {
            await onSearch();
          }
        }}
      />

      <Popover className="relative mr-4 flex items-center">
        {({ open }) => (
          <>
            <Popover.Button className={"outline-none"}>
              <FilterSVG
                className={
                  "group-focus-within:fill-white group-focus-within:stroke-white"
                }
              />
            </Popover.Button>
            {open && <FilterModal />}
          </>
        )}
      </Popover>

      {hasText && (
        <button
          className={clsx(
            "rounded-[10px] bg-essayz-purple py-2 px-4 text-[13px] text-white",
            "group-focus-within:bg-white group-focus-within:text-essayz-purple",
            "transition-colors duration-500 hover:group-focus-within:bg-essayz-border-gray"
          )}
          onClick={onSearch}
        >
          Go!
        </button>
      )}
    </div>
  );
};

const FilterModal: React.FC = () => {
  const authors = useMemo(() => ["Vitalik Buterin", "Paul Graham"], []);
  const selectedAuthorsFromStore = useSearchStore(
    (state) => state.selectedAuthors
  );
  const selectAuthors = useSearchStore((state) => state.selectAuthors);
  const fetchResult = useSearchStore((state) => state.fetchResult);
  const query = useSearchStore((state) => state.query);

  const [selectedAuthors, setSelectedAuthors] = useState(
    selectedAuthorsFromStore
  );
  useEffect(
    () => setSelectedAuthors(selectedAuthorsFromStore),
    [selectedAuthorsFromStore]
  );

  const selectAuthor = useCallback(
    (author: string) => {
      if (selectedAuthors.includes(author)) {
        setSelectedAuthors(selectedAuthors.filter((i) => i !== author));
      } else {
        setSelectedAuthors([...selectedAuthors, author]);
      }
    },
    [selectedAuthors]
  );
  const onApply = useCallback(() => {
    selectAuthors(selectedAuthors);
    if (query.trim().length > 0) {
      return fetchResult(query, selectedAuthors);
    }
  }, [selectedAuthors, fetchResult, query, selectAuthors]);

  return (
    <Popover.Panel
      className={clsx(
        "absolute top-12 -left-[245px] z-10 min-w-[300px] rounded-[10px] bg-white py-10 px-6",
        "lg:top-8 lg:left-0"
      )}
      style={{
        boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.25)",
      }}
    >
      <div className={"flex flex-row items-center justify-between"}>
        <div className={"text-lg font-bold text-essayz-black"}>
          Filter essays by author
        </div>
        <Popover.Button>
          <CloseSVG />
        </Popover.Button>
      </div>
      <div className={"mt-7 flex flex-col gap-y-5"}>
        {authors.map((author) => {
          const checked = selectedAuthors.includes(author);

          return (
            <button
              key={author}
              className={"flex select-none flex-row items-center"}
              onClick={() => selectAuthor(author)}
            >
              {checked ? <CheckedSVG /> : <CheckEmptySVG />}
              <div className={"ml-4 text-lg text-essayz-black"}>{author}</div>
            </button>
          );
        })}
      </div>
      <div className={"mt-6 flex flex-row items-center justify-end gap-x-4"}>
        <Popover.Button
          className={"p-4 py-2 text-[13px] text-essayz-text-gray"}
        >
          Cancel
        </Popover.Button>
        <Popover.Button
          className={
            "rounded-[10px] bg-essayz-purple p-4 py-2 text-[13px] text-white"
          }
          onClick={onApply}
        >
          Save
        </Popover.Button>
      </div>
    </Popover.Panel>
  );
};
