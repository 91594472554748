import React from "react";
import { LeftPanel } from "./components/LeftPanel";
import { RightPanel } from "./components/RightPanel";

const App: React.FC = () => {
  return (
    <div className={`h-full w-full font-sans`}>
      <div
        className={
          "m-auto flex h-[100vh] flex-col lg:min-h-[400px] lg:flex-row"
        }
      >
        <LeftPanel />
        <RightPanel />
      </div>
    </div>
  );
};

export default App;
