import React, { useMemo, useState } from "react";
import { ArrowLeftSVG, LightBulbSVG, ListSVG, ZoomInSVG } from "../assets/svgs";
import ReactMarkdown from "react-markdown";
import { CONTACT_EMAIL } from "../assets/constants";
import { SuggestionButton } from "./SuggestionButton";
import { useSearchStore } from "../store/search";
import { Transition } from "@headlessui/react";

export const RightPanel: React.FC = () => {
  const fetching = useSearchStore(
    (state) => state.fetchingResult || state.fetchingAnswer || !!state.answer
  );

  if (!fetching) {
    return <EmptyState />;
  }

  return <Result />;
};

const Answering: React.FC = () => {
  const answer = useSearchStore((state) => state.answer);

  return (
    <div>
      <div className={"text-2xl font-bold text-essayz-black"}>Answer</div>
      <ReactMarkdown
        className={"mt-4 min-h-[160px] text-lg text-essayz-black [&>p]:pb-4"}
      >
        {answer || "..."}
      </ReactMarkdown>
    </div>
  );
};

const References: React.FC = () => {
  const results = useSearchStore((state) => state.results);
  const [isListView, setIsListView] = useState(false);

  if (results.length === 0) return null;

  return (
    <>
      <div className={"flex flex-row items-center justify-between"}>
        <div className={"text-2xl font-bold text-essayz-black"}>References</div>
        <button
          className={
            "flex w-[120px] flex-row items-center justify-center rounded-[10px] border border-essayz-border-gray bg-essayz-bg-gray py-2 px-4 transition-colors duration-500 hover:bg-essayz-border-gray/50"
          }
          onClick={() => setIsListView(!isListView)}
        >
          {isListView ? <ZoomInSVG /> : <ListSVG />}
          <span className={"ml-2 text-base text-essayz-text-gray"}>
            {isListView ? "Hide list" : "View list"}
          </span>
        </button>
      </div>
      <div className={"mt-4"}>
        <Transition
          show={isListView}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {results.map((result, index) => {
            const number = `${index + 1 < 10 ? "0" : ""}${index + 1}`;

            return (
              <div
                key={`list-item-${result.id}`}
                className={"border-b border-b-essayz-border-gray py-4"}
              >
                <div className={"text-base text-essayz-text-gray"}>
                  <span className={"font-bold"}>{result.author}</span> -{" "}
                  <span>{result.date}</span>
                </div>
                <a
                  href={result.url}
                  target={"_blank"}
                  className={
                    "text-lg font-bold text-essayz-blue hover:underline"
                  }
                  rel="noreferrer"
                >
                  {`#${number} - ${result.title}`}
                </a>
                <p className={"mt-2 text-lg line-clamp-3"}>{result.content}</p>
              </div>
            );
          })}
        </Transition>
        <Transition
          show={!isListView}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className={"flex flex-row flex-wrap gap-2"}
        >
          {results.map((result, index) => {
            const number = `${index + 1 < 10 ? "0" : ""}${index + 1}`;

            return (
              <a
                href={result.url}
                target={"_blank"}
                key={`button-item-${result.id}`}
                rel="noreferrer"
              >
                <SuggestionButton type={"button"}>
                  <span>{number}</span>
                  <span
                    className={"mx-2 h-full w-[1px] bg-essayz-text-gray/30"}
                  ></span>
                  {result.title}
                </SuggestionButton>
              </a>
            );
          })}
        </Transition>
      </div>
    </>
  );
};

const Result: React.FC = () => {
  const fetchingResult = useSearchStore((state) => state.fetchingResult);

  return (
    <div
      className={"scrollbar-gutter-stable w-full overflow-y-auto p-8 lg:p-16"}
    >
      <Transition
        show={fetchingResult}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={"absolute"}
      >
        <div className={"flex flex-row items-center"}>
          <img
            src={"./loading.svg"}
            width={32}
            height={32}
            alt={"loading..."}
          />
          <div className={"ml-2 text-[32px] font-bold text-essayz-text-gray"}>
            Preparing your results...
          </div>
        </div>
      </Transition>

      <Transition
        show={!fetchingResult}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Answering />
        <div className={"mt-[75px]"}>
          <References />
          <div className={"mt-14"}>
            <div className={"text-base text-essayz-text-gray"}>
              Want your own version? Who should be next
            </div>
            <div className={"mt-2 text-base text-essayz-text-gray"}>
              Let us know at{" "}
              <a
                className={"font-bold text-essayz-purple"}
                href={`mailto:${CONTACT_EMAIL}`}
              >
                {CONTACT_EMAIL}
              </a>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

const EmptyState: React.FC = () => {
  const suggestedIdeas = useMemo(
    () => [
      "How to start a startup?",
      "How to be wealthy?",
      "How can I build muscle?",
      "What's the healthiest morning routine?",
      "How to price my projects?",
      "How to pivot a crypto?",
      "What to do when people copy your startup?",
    ],
    []
  );
  const fetchResult = useSearchStore((state) => state.fetchResult);
  const selectedAuthors = useSearchStore((state) => state.selectedAuthors);

  return (
    <div className={"flex flex-1 flex-col p-8 lg:p-16"}>
      <div className={"h-1/2"} />
      <div className={"mt-8 flex h-1/2 flex-col justify-between"}>
        <div className={"flex flex-row items-center"}>
          <ArrowLeftSVG />
          <div className={"ml-4 text-xl text-essayz-text-gray"}>
            Ask anything...
          </div>
        </div>
        <div>
          <div className={"mt-4 flex flex-row items-center"}>
            <LightBulbSVG />
            <div className={"ml-2 text-xl font-medium"}>Need some ideas?</div>
          </div>
          <div className={"my-4 flex flex-row flex-wrap gap-4"}>
            {suggestedIdeas.map((idea) => (
              <SuggestionButton
                key={idea}
                onClick={() => fetchResult(idea, selectedAuthors)}
              >
                {idea}
              </SuggestionButton>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
