import React, { SVGProps } from "react";

export const LogoDecorSVG: React.FC<SVGProps<any>> = (props) => (
  <svg
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.45159 4.17743C5.78397 4.17743 6.86774 5.49972 6.86774 7.12305H6.86756C6.86756 7.34986 7.0492 7.53365 7.27311 7.53365C7.49702 7.53365 7.67848 7.34986 7.67848 7.12305C7.67848 5.49972 8.76225 4.17743 10.0946 4.17743C10.3186 4.17743 10.5 3.99363 10.5 3.76683C10.5 3.54002 10.3186 3.35623 10.0946 3.35623C8.76225 3.35623 7.67848 2.03393 7.67848 0.4106C7.67848 0.183796 7.49702 0 7.27311 0C7.0492 0 6.86756 0.183796 6.86756 0.4106C6.86756 2.03393 5.78379 3.35623 4.4514 3.35623H4.45159C4.22767 3.35623 4.04604 3.54002 4.04604 3.76683C4.04604 3.99363 4.22768 4.17743 4.45159 4.17743Z"
      fill="#A855F7"
    />
    <path
      d="M0.718374 8.21002C1.43581 8.21002 2.01938 8.91524 2.01938 9.78102H2.01928C2.01928 9.90198 2.11709 10 2.23766 10C2.35822 10 2.45593 9.90198 2.45593 9.78102C2.45593 8.91524 3.0395 8.21002 3.75694 8.21002C3.87751 8.21002 3.97521 8.11199 3.97521 7.99103C3.97521 7.87007 3.87751 7.77204 3.75694 7.77204C3.0395 7.77204 2.45593 7.06682 2.45593 6.20104C2.45593 6.08008 2.35822 5.98206 2.23766 5.98206C2.11709 5.98206 2.01928 6.08008 2.01928 6.20104C2.01928 7.06682 1.43571 7.77204 0.718275 7.77204H0.718374C0.597805 7.77204 0.5 7.87007 0.5 7.99103C0.5 8.11199 0.597808 8.21002 0.718374 8.21002Z"
      fill="#A855F7"
    />
  </svg>
);

export const SearchSVG: React.FC<SVGProps<unknown>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
        stroke="#97A3B6"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={props.className}
      />
    </g>
  </svg>
);

export const ArrowLeftSVG: React.FC<SVGProps<string>> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 19L3 12M3 12L10 5M3 12L21 12"
      stroke="#97A3B6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LightBulbSVG: React.FC<SVGProps<unknown>> = () => (
  <svg
    width="27"
    height="28"
    viewBox="0 0 27 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8715 19.625H16.1285M13.5 3.875V5M20.6595 6.84054L19.864 7.63604M23.6251 13.9999H22.5001M4.50006 13.9999H3.37506M7.13604 7.63604L6.34054 6.84054M9.52253 17.9775C7.32583 15.7808 7.32583 12.2192 9.52253 10.0226C11.7192 7.82585 15.2808 7.82585 17.4775 10.0226C19.6742 12.2192 19.6742 15.7808 17.4775 17.9775L16.862 18.593C16.15 19.305 15.75 20.2707 15.75 21.2776V21.875C15.75 23.1176 14.7426 24.125 13.5 24.125C12.2574 24.125 11.25 23.1176 11.25 21.875V21.2776C11.25 20.2707 10.85 19.305 10.138 18.593L9.52253 17.9775Z"
      stroke="#97A3B6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CloseSVG: React.FC<SVGProps<unknown>> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 18L18 6M6 6L18 18"
      stroke="#97A3B6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ListSVG: React.FC<SVGProps<unknown>> = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.8335 5H17.1668M3.8335 8.33333H17.1668M3.8335 11.6667H17.1668M3.8335 15H17.1668"
      stroke="#97A3B6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FilterSVG: React.FC<SVGProps<unknown>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      d="M1.6667 4.99994C1.6667 4.53969 2.03974 4.16666 2.49999 4.16666H17.5C17.9603 4.16666 18.3333 4.53969 18.3333 4.99994C18.3333 5.46019 17.9603 5.83323 17.5 5.83323H2.49999C2.03974 5.83323 1.6667 5.46019 1.6667 4.99994Z"
      fill="#97A3B6"
      className={props.className}
    />
    <path
      d="M4.16672 9.99997C4.16672 9.53972 4.53975 9.16669 5 9.16669H15C15.4603 9.16669 15.8333 9.53972 15.8333 9.99997C15.8333 10.4602 15.4603 10.8333 15 10.8333H5C4.53975 10.8333 4.16672 10.4602 4.16672 9.99997Z"
      fill="#97A3B6"
      className={props.className}
    />
    <path
      d="M7.49988 14.1667C7.03963 14.1667 6.6666 14.5398 6.6666 15C6.6666 15.4603 7.03963 15.8333 7.49988 15.8333H12.4999C12.9601 15.8333 13.3332 15.4603 13.3332 15C13.3332 14.5398 12.9601 14.1667 12.4999 14.1667H7.49988Z"
      fill="#97A3B6"
      className={props.className}
    />
  </svg>
);

export const CheckEmptySVG: React.FC<SVGProps<unknown>> = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5651 0.766296H21.3998C22.5602 0.766296 23.4685 1.66468 23.5 2.83837V21.7012C23.5 22.8306 22.5642 23.7663 21.4349 23.7663H2.5651C1.43575 23.7663 0.5 22.8305 0.5 21.7012V2.8314C0.5 1.70204 1.43579 0.766296 2.5651 0.766296Z"
      stroke="#97A3B6"
    />
  </svg>
);

export const CheckedSVG: React.FC<SVGProps<unknown>> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3998 0H2.5651C1.15966 0 0 1.1596 0 2.5651V21.4349C0 22.8403 1.1596 24 2.5651 24H21.4349C22.8403 24 24 22.8404 24 21.4349V2.56573C23.965 1.12496 22.8404 0.000376285 21.3996 0.000376285L21.3998 0ZM5.6925 11.2797C6.07904 10.8932 6.71141 10.8932 7.09795 11.2797L9.80373 13.9855C9.90913 14.0909 10.0498 14.0909 10.1552 13.9855L16.8668 7.27389C17.2534 6.88735 17.8857 6.88735 18.2723 7.27389C18.6588 7.66044 18.6588 8.29281 18.2723 8.67934L10.3659 16.5857C10.1551 16.7965 9.80365 16.7965 9.5578 16.5857L5.6926 12.6852C5.30606 12.2987 5.30606 11.6663 5.6926 11.2798L5.6925 11.2797Z"
      fill="#A855F7"
    />
  </svg>
);

export const ZoomInSVG: React.FC<SVGProps<unknown>> = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.87497 8.43291H2.72175C2.56238 8.43291 2.45619 8.37972 2.34982 8.27353C2.24363 8.16735 2.19045 8.06098 2.19045 7.9016C2.19045 7.58286 2.40301 7.3703 2.72175 7.3703H6.54671L1.65942 2.42974C1.44686 2.21718 1.44686 1.89844 1.65942 1.68606C1.87198 1.4735 2.19072 1.4735 2.4031 1.68606L7.29039 6.57334V2.74839C7.29039 2.42965 7.50294 2.21709 7.82169 2.21709C8.14044 2.21709 8.35299 2.42965 8.35299 2.74839V7.90115C8.35299 7.95433 8.35299 8.06052 8.29981 8.11371C8.24662 8.21989 8.14044 8.32626 8.03425 8.37927C7.98124 8.37927 7.92806 8.43245 7.87488 8.43245L7.87497 8.43291ZM12.125 11.6204H17.2782C17.4376 11.6204 17.5438 11.6736 17.6502 11.7797C17.7564 11.8859 17.8096 11.9923 17.8096 12.1517C17.8096 12.4704 17.597 12.683 17.2782 12.683H13.4533L18.3406 17.5703C18.5531 17.7828 18.5531 18.1016 18.3406 18.3139C18.128 18.5265 17.8093 18.5265 17.5969 18.3139L12.6563 13.4795V17.3044C12.6563 17.6232 12.4438 17.8357 12.125 17.8357C11.8063 17.8357 11.5937 17.6232 11.5937 17.3044L11.5939 12.1517C11.5939 12.0985 11.5939 11.9923 11.6471 11.9391C11.7003 11.8329 11.8065 11.7266 11.9127 11.6736C12.0188 11.6736 12.072 11.6204 12.1252 11.6204H12.125Z"
      fill="#97A3B6"
    />
  </svg>
);
