import React from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: React.ReactNode;
}
export const SuggestionButton: React.FC<Props> = ({ children, ...props }) => {
  return (
    <button
      className={
        "flex items-center rounded-full border border-essayz-border-gray bg-essayz-bg-gray py-2 px-4 text-base text-essayz-text-gray transition-colors duration-500 hover:bg-essayz-border-gray/50"
      }
      {...props}
    >
      {children}
    </button>
  );
};
