import { SearchResult } from "../types";
import { create } from "zustand";
import { queryClient } from "../index";
import { fetchAnswer, fetchResults } from "../api";

interface SearchState {
  query: string;
  fetchingResult: boolean;
  fetchingAnswer: boolean;
  results: SearchResult[];
  answer: string;
  selectedAuthors: string[];
  fetchResult(query: string, authors: string[]): Promise<void>;
  selectAuthors(authors: string[]): void;
}

const initialState = {
  query: "",
  fetchingResult: false,
  fetchingAnswer: false,
  results: [],
  answer: "",
  selectedAuthors: [],
};

const decoder = new TextDecoder();
export const useSearchStore = create<SearchState>((set, get) => ({
  ...initialState,
  fetchResult: async (query: string, selectedAuthors: string[]) => {
    set({
      ...initialState,
      query,
      selectedAuthors,
      fetchingResult: true,
    });
    const results = await queryClient.executeMutation({
      mutationFn: () => fetchResults(query, selectedAuthors),
      mutationKey: ["search", query],
    });
    set({
      answer: "",
      results,
      fetchingResult: false,
      fetchingAnswer: true,
    });

    const response = await fetchAnswer(query, results);
    if (response.body) {
      const reader = response.body.getReader();

      let result: ReadableStreamReadResult<Uint8Array>;
      let answer = "";
      do {
        result = await reader.read();
        const chunkValue = decoder.decode(result.value);
        answer += chunkValue;

        set({ fetchingAnswer: true, answer });
      } while (!result?.done && !get().fetchingResult);

      set({ fetchingAnswer: false });
    }
  },
  selectAuthors(authors: string[]) {
    set({ selectedAuthors: authors });
  },
}));
