import React, { useEffect, useMemo, useRef } from "react";
import { CloseSVG, LogoDecorSVG } from "../assets/svgs";
import { CONTACT_EMAIL } from "../assets/constants";
import Drawer from "./Drawer";
import ReactMarkdown from "react-markdown";
import clsx from "clsx";

interface Props {
  opened: boolean;
  onClose(): void;
  contentId: string;
}

export const DrawerContent: React.FC<Props> = ({
  opened,
  onClose,
  contentId,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const el = ref.current?.ownerDocument.getElementById(contentId);
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [contentId]);

  const faqs = useMemo(
    () => [
      {
        id: "what",
        q: "What is this?",
        a: `Have you ever wanted to know what your favourite thinkers say about specific topics without having to search through all their writings to find the answer? or struggled to find the information you needed from your favourite essays after scrolling though irrelevant Google results? Essayz indexes and analyses ideas from your favourite essays, providing you with instant answers to your questions on a wide range of topics.
        
The "Answer" functionality does a search for your question and then answers based on the most relevant results. This way, you get answers to your questions, not just what AI thinks. 

The search works on semantic meaning, not just keywords, allowing you to search for specific topics and get relevant answers, even if the exact words aren't used.`,
      },
      {
        id: "how",
        q: "How does it work?",
        a: `1. Indexing Essays:
        
- The content from essays is ingested.
- The essays are preprocessed into chunks of text, ranging from sentence to paragraph size.
- Each chunk is encoded into a vector representation.
- The vector representations of the chunks are indexed in Postgres using the pgvector extension.

2. Retriever Model:
        
- When a question is asked, the question is encoded into a vector representation.
- The question vector is compared against the embedded vectors of the essays to find the knowledge-based text with the closest semantic meaning to the question.
- Cosine similarity, a mathematical concept used to find the similarity between two vectors, is used to determine the relationship between the two vectors. Cosine similarity is represented between 0 and 1, with 1 indicating identical vectors.
- By using cosine similarity, the chunks containing meanings most closely related to the question are retrieved.

3. Generator Model
- The question and relevant content are fed into a text generation model, specifically GPT-3.
- Other text generation models that can also be used include BERT, BLOOM and so on.
- The model uses the question and context to generate an answer.
- This approach ensures that the generated content is relevant and does not contain made-up information.`,
      },
      {
        id: "contact",
        q: "Can you do this for my content?\n",
        a: `Sure, simply send us an email at [${CONTACT_EMAIL}](mailto:${CONTACT_EMAIL}) and tell us about your favourite essay, along with a website link to where it can be found.`,
      },
    ],
    []
  );

  return (
    <Drawer
      size={556}
      direction={"right"}
      open={opened}
      duration={350}
      zIndex={9999999}
      lockBackgroundScroll={true}
      onClose={onClose}
    >
      <div
        ref={ref}
        className={"flex h-full flex-col overflow-y-auto bg-white px-16 pb-16"}
      >
        <div id={"top"} className={"pt-10"} />
        <button onClick={onClose} className={"self-end"}>
          <CloseSVG />
        </button>
        <LogoDecorSVG width={22} height={22} />
        <h3 className={"mt-2 text-xl font-bold text-essayz-text-gray"}>
          Frequently Asked Questions
        </h3>
        {faqs.map(({ id, a, q }, index) => (
          <div className={"pt-10"} key={`${id}_${index}`} id={id}>
            <h3 className={"text-xl font-bold text-essayz-black"}>{q}</h3>
            <ReactMarkdown
              className={clsx(
                "text-lg text-essayz-black",
                "[&>p]:py-2",
                "[&>ol]:list-decimal [&>ol]:pl-5 [&>ol]:pt-2",
                "[&>ul]:list-disc [&>ul]:pl-5 [&>ul]:pb-2",
                "[&>p>a]:font-bold [&>p>a]:text-essayz-blue"
              )}
            >
              {a}
            </ReactMarkdown>
          </div>
        ))}

        <div className={"pt-20 text-base text-essayz-text-gray"}>
          <div>Want your own version? Who should be next?</div>
          <div>
            Let us know at{" "}
            <a
              href={`mailto:${CONTACT_EMAIL}`}
              className={"font-bold text-essayz-purple"}
            >
              {CONTACT_EMAIL}
            </a>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
