// ref: https://github.com/Farzin-Firoozi/react-modern-drawer/
import React, { useEffect } from "react";
import type { CSSProperties } from "react";
import clsx from "clsx";

type Props = {
  open: boolean;
  direction: "left" | "right" | "top" | "bottom";
  lockBackgroundScroll?: boolean;
  children?: React.ReactNode;
  duration?: number;
  overlayOpacity?: number;
  overlayColor?: String;
  enableOverlay?: boolean;
  style?: React.CSSProperties;
  zIndex?: number;
  size?: number | string;
  onClose?(): void;
};

const Drawer: React.FC<Props> = (props) => {
  const {
    open,
    children,
    style,
    enableOverlay = true,
    overlayColor = "#000",
    overlayOpacity = 0.4,
    zIndex = 100,
    duration = 500,
    lockBackgroundScroll = false,
    onClose,
  } = props;

  useEffect(() => {
    if (lockBackgroundScroll) {
      const htmlEl = window.document.querySelector("html");
      if (htmlEl) {
        htmlEl.style.overflowY = open ? "hidden" : "auto";
      }
    }
  }, [open, lockBackgroundScroll]);

  const overlayStyles: CSSProperties = {
    backgroundColor: `${overlayColor}`,
    opacity: open ? `${overlayOpacity}` : 0,
    zIndex: zIndex,
    pointerEvents: open ? "auto" : `none`,
  };

  const drawerStyles: CSSProperties = {
    zIndex: zIndex + 1,
    transitionDuration: `${duration}ms`,
    top: 0,
    right: 0,
    transform: "translate3d(100%, 0, 0)",
    height: "100vh",
    ...style,
  };

  return (
    <>
      <nav
        role="navigation"
        style={drawerStyles}
        className={clsx("drawer-container w-5/6 max-w-[556px]", {
          "drawer-opened": open,
        })}
      >
        {children}
      </nav>
      {enableOverlay && (
        <div
          className={"drawer-overlay"}
          onClick={onClose}
          style={overlayStyles}
        />
      )}
    </>
  );
};

export default Drawer;
