import axios, {
  Method,
  AxiosRequestConfig,
  AxiosInstance,
  ResponseType,
} from "axios";
import axiosRetry from "axios-retry";
import { SearchResult } from "./types";

const baseURL = "https://wvsmdukwftjapwwmpbqg.functions.supabase.co";
const baseInstance = axios.create({ baseURL });
axiosRetry(baseInstance, { retries: 3 });
const Authorization = [
  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Ind2c21kdWt3ZnRqYXB3d21wYnFnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzgxMTgxNTMsImV4cCI6MTk5MzY5NDE1M30.8Duut1uvnd_DfZATxo11LnuFj6w_CMonMlx1ivNYIZE",
].join(", ");

const request = ({
  method,
  url,
  data,
  isMultipart = false,
  instance = baseInstance,
  responseType = "json",
}: {
  method: Method;
  url: string;
  data: any;
  isMultipart?: boolean;
  instance?: AxiosInstance;
  responseType?: ResponseType;
}): Promise<any> =>
  new Promise((resolve, reject) => {
    (async () => {
      const requestConfig: Partial<AxiosRequestConfig> = {
        url,
        method,
        headers: {
          Authorization,
          "Content-Type": isMultipart
            ? "multipart/form-data"
            : "application/json",
          "Access-Control-Allow-Origin": "*",
          Accept: "*/*",
        },
        responseType: responseType,
      };

      if (method === "get") {
        return instance.request({ ...requestConfig, params: data });
      }

      return instance.request({ ...requestConfig, data });
    })()
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });

// const get = <T = any>(endpoint: string, data: any = {}): Promise<T> =>
//   request({ method: "get", url: endpoint, data });

const post = (
  endpoint: string,
  data: any = {},
  responseType: ResponseType = "json"
): Promise<any> =>
  request({ method: "post", url: endpoint, data, responseType });

// const postMultipart = (endpoint: string, data: any): Promise<any> =>
//   request({ method: "post", url: endpoint, data, isMultipart: true });

// const put = (endpoint: string, data: any = {}): Promise<any> =>
//   request({ method: "put", url: endpoint, data });

// const del = (endpoint: string, data: any = {}): Promise<any> =>
//   request({ method: "delete", url: endpoint, data });

export const fetchResults = async (
  query: string,
  authors: string[]
): Promise<SearchResult[]> => {
  return post("/essay-search", {
    query,
    ...(authors.length > 0
      ? { authors: authors.map((item) => item.toLowerCase()) }
      : {}),
  });
};

export const fetchAnswer = async (
  query: string,
  results: SearchResult[]
): Promise<Response> => {
  return fetch(`${baseURL}/essay-answer`, {
    method: "POST",
    headers: {
      Authorization,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Accept: "*/*",
    },
    body: JSON.stringify({ query, results }),
  });
};
